// Network Configuration
export const NetworkContextName = "Mainnet";
export const ACTIVE_NETWORK = 1;
export const RPC_URL =
  "https://mainnet.infura.io/v3/0b9fb9aae3b3437bafb83822f36e59df";
export const MarketplaceContract = "0x64eDFDd074C220EE57fd2001B6060C980A0687D0";
export const PrebuyContractAddress =
  "0xf3360895CFcE648C5d3E3E09e7443c5d837f6ef1";
export const AdminWalletAddress = "0x5b76cf9Fa3287f486363769b9C967310D4Fd48A5";
export const baseURI =
  "https://ipfs.io/ipfs/QmNzE3qP1w7ZHLkzLHXLdBR94fdR7Sa79tCzGV2xD3DjCg";

// Time Pending
export const countDownDate = new Date("January 1, 2023 08:00:00 PST").getTime();
export const countDownDate1 = countDownDate.toLocaleString("en-US", {
  timeZone: "America/New_York",
});
