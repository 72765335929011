import React, { createContext, useState, useEffect } from "react";
import axios from "axios";
import { useWeb3React } from "@web3-react/core";
import { injected } from "src/connectors";
import {
  getBalanceOf,
  getContract,
  getWeb3ContractObject,
  getWeb3Obj,
} from "src/utils";
import {
  AdminWalletAddress,
  MarketplaceContract,
  PrebuyContractAddress,
} from "src/constants";
import MarketplaceContractABI from "src/abis/MarketplaceContractABI.json";
import PrebuyContractABI from "src/abis/PrebuyContract.json";

export const AuthContext = createContext();

const setSession = (accessToken) => {
  if (accessToken) {
    localStorage.setItem("creatturAccessToken", accessToken);
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  } else {
    localStorage.removeItem("creatturAccessToken");
    delete axios.defaults.headers.common.Authorization;
  }
};

function checkLogin() {
  const accessToken = window.localStorage.getItem("creatturAccessToken");
  return accessToken ? true : false;
}

export default function AuthProvider(props) {
  const [isLogin, setIsLogin] = useState(checkLogin());
  const [isAdmin, setIsAdmin] = useState(false);
  const [userData] = useState({});
  const [nftPrice, setNftPrice] = useState(0);
  const [preBuyPrice, setpreBuyPrice] = useState(0);
  const [maxNftSuppy, setMaxNftSuppy] = useState(0);
  const [adminAccount, SetAdminAccount] = useState(AdminWalletAddress);
  const [isPresaleStarted, setIspresaleStarted] = useState(false);
  const [isPublicSaleStarted, setIspublicSaleStarted] = useState(false);
  const [totalSupply, setTotalSupply] = useState(0);
  const [mintPercentage, setMintPercentage] = useState(0);
  const [userNFTList, setUserNFTList] = useState([]);
  const [balanceOfValue, setBalanceOfValue] = useState();
  const [hasFinalSaleStarted, sethasFinalSaleStarted] = useState(false);
  const [allNftList, setallNftList] = useState([]);
  const [MAX_RAMS_WALLET, setMAX_RAMS_WALLET] = useState(0);
  const [amountClaimedBy, setAmountClaimedBy] = useState(0);
  const [walletBalance, setWalletBalance] = useState(0);
  const [hideMint, setHideMint] = useState(false);
  const { activate, deactivate, account, library, chainId } = useWeb3React();

  // const allNFTListHandler = async (txaCount) => {
  //   setUserNFTList([]);
  //   const contract = await getWeb3ContractObject(
  //     MarketplaceContractABI,
  //     MarketplaceContract
  //   );
  //   try {
  //     for (let i = 1; i <= parseInt(txaCount); i++) {
  //       const tokenURI = await contract.methods.tokenURI(i.toString()).call();

  //       const res = await axios.get(tokenURI);

  //       if (res.status === 200) {
  //         setallNftList((prev) => [
  //           ...prev,
  //           { id: i.toString(), nfdData: res.data },
  //         ]);
  //       }
  //     }
  //   } catch (error) {
  //     setallNftList([]);
  //     console.log("ERROR", error);
  //   }
  // };

  // useEffect(() => {
  //   if (totalSupply > 0) {
  //     // allNFTListHandler(totalSupply);
  //   }
  // }, [totalSupply]);

  const getPreBuyBatchNumByUser = async () => {
    const preBuyContract = await getWeb3ContractObject(
      PrebuyContractABI,
      PrebuyContractAddress
    );
    const batchNum = await preBuyContract.methods
      .getPreBuyBatchNumByUser(account)
      .call();
    return batchNum;
  };

  const getBasicDetailsHandler = async () => {
    try {
      // allNFTListHandler();
      getBalanceOfFun();
      const web3 = await getWeb3Obj();
      const contract = await getWeb3ContractObject(
        MarketplaceContractABI,
        MarketplaceContract
      );
      const preBuyContract = await getWeb3ContractObject(
        PrebuyContractABI,
        PrebuyContractAddress
      );
      console.log("preBuyContract methods===>", preBuyContract.methods);
      const _preBuyPrice = await preBuyContract.methods._preBuyPrice().call();

      setpreBuyPrice(web3.utils.fromWei(_preBuyPrice));
      const totalSupplyL = await contract.methods.totalSupply().call();
      const adminAccount = await contract.methods.owner().call();
      const MAX_RAMS_L = await contract.methods.MAX_RAMS().call();
      let percentage =
        (parseFloat(totalSupplyL) / parseFloat(MAX_RAMS_L)) * 100;
      const presaleStarted = await contract.methods.presaleStarted().call();
      const publicSaleStarted = await contract.methods
        .publicSaleStarted()
        .call();

      setIspresaleStarted(presaleStarted);
      setIspublicSaleStarted(publicSaleStarted);

      if (presaleStarted) {
        setMAX_RAMS_WALLET(1);
      } else {
        const MAX_RAMS_WALLET = await contract.methods.MAX_RAMS_WALLET().call();
        setMAX_RAMS_WALLET(MAX_RAMS_WALLET.toString());
      }
      if (account) {
        const amountClaimedBy = await contract.methods
          .amountClaimedBy(account)
          .call();
        setAmountClaimedBy(amountClaimedBy.toString());

        var balance = web3.eth.getBalance(account);
        var wallet = web3.utils.fromWei((await balance).toString());
        setWalletBalance(wallet);
      }

      if (presaleStarted) {
        const PRE_SALE_PRICE_L = await contract.methods.PRE_SALE_PRICE().call();
        setNftPrice(web3.utils.fromWei(PRE_SALE_PRICE_L));
        setHideMint(false);
      } else if (publicSaleStarted) {
        const PUBLIC_SALE_PRICE_L = await contract.methods
          .PUBLIC_SALE_PRICE()
          .call();
        setNftPrice(web3.utils.fromWei(PUBLIC_SALE_PRICE_L));
        setHideMint(false);
      } else {
        const PRE_SALE_PRICE_L = await contract.methods.PRE_SALE_PRICE().call();
        setNftPrice(web3.utils.fromWei(PRE_SALE_PRICE_L));
        setHideMint(true);
      }
      setMaxNftSuppy(MAX_RAMS_L);
      SetAdminAccount(adminAccount);
      setTotalSupply(totalSupplyL);
      setMintPercentage(parseFloat(percentage).toFixed(2));
    } catch (error) {
      console.log("ERROR", error);
    }
  };

  useEffect(() => {
    getBasicDetailsHandler();
  }, [account]);

  async function getBalanceOfFun() {
    setBalanceOfValue(
      await getBalanceOf(MarketplaceContractABI, MarketplaceContract, account)
    );
  }

  useEffect(() => {
    if (account) {
      getBalanceOfFun();
    }
    if (account && adminAccount) {
      account.toLowerCase() == adminAccount.toLowerCase()
        ? setIsAdmin(true)
        : setIsAdmin(false);
    }
  }, [account, chainId, adminAccount]); // eslint-disable-line react-hooks/exhaustive-deps

  const walletConnect = async () => {
    try {
      activate(injected, undefined, true).catch((error) => {
        if (error) {
          console.log("ERROR", error);
          const errorMSG = error.message; //+ ' Please install Metamask';
          alert(errorMSG);
          activate(injected);
        }
      });
    } catch (error) {
      console.log("ERROR", error);
    }
  };

  const disconnectWallet = async () => {
    deactivate();
  };

  let data = {
    userLoggedIn: isLogin,
    userData,
    preBuyPrice,
    nftPrice,
    maxNftSuppy,
    adminAccount,
    totalSupply,
    mintPercentage,
    balanceOfValue,
    isAdmin,
    isPresaleStarted,
    isPublicSaleStarted,
    MAX_RAMS_WALLET,
    amountClaimedBy,
    walletBalance,
    hideMint,
    getPreBuyBatchNumByUser: () => getPreBuyBatchNumByUser(),
    walletConnect: () => walletConnect(),
    disconnectWallet: () => disconnectWallet(),
    getBalanceOfFun: () => getBalanceOfFun(),
    getBasicDetailsHandler: () => getBasicDetailsHandler(),
    userLogIn: (type, data) => {
      setSession(data);
      setIsLogin(type);
    },
  };

  return (
    <>
    <img height="1" width="1" style={{display: "none"}} alt="" src="https://analytics.twitter.com/i/adsct?txn_id=o8v0r&p_id=Twitter&tw_sale_amount=0&tw_order_quantity=0" />
    <img height="1" width="1" style={{display: "none"}}  alt="" src="//t.co/i/adsct?txn_id=o8v0r&p_id=Twitter&tw_sale_amount=0&tw_order_quantity=0" />
    <AuthContext.Provider value={data}>{props.children}</AuthContext.Provider>
    </>
    
  );
}
